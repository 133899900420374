.current-date {
  font-family: serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 20px;
  color: #000000;
}
.StatusSkyBlue {
  box-shadow: rgb(0 229 255 / 35%) 0px 2px 15px;
  border: 1px solid #68fff1 !important;
  border-radius: 7px;
  margin-top: 16px;
  width: 100%;
}
.StatusPayCut {
  // box-shadow: rgba(16, 24, 168, 0.35) 0px 2px 15px;
  // border: 1px solid rgb(2, 11, 186) !important;
  box-shadow: rgb(255 0 0 / 35%) 0px 2px 15px;
  border: 1px solid rgb(217 67 67) !important;
  border-radius: 7px;
  margin-top: 16px;
  width: 100%;
  background-color: rgb(255 0 0 / 35%);
}
.StatusGreen {
  box-shadow: rgb(0 255 0 / 35%) 0px 2px 15px;
  border: 1px solid #00ff00 !important;
  border-radius: 7px;
  margin-top: 15px;
  width: 100%;
  .StatusGreen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }
}
.StatusRed {
  box-shadow: rgb(240 103 98 / 35%) 0px 2px 15px;
  border: 1px solid #fab9b6;
  margin-top: 16px;
  border-radius: 5px;
  width: 100%;
  .statusRed {
    display: flex;
    font-family: serif;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }
}

.StatusGrey {
  box-shadow: rgb(202 204 203 / 70%) 0px 2px 15px;
  border: 1px solid #cacccb;
  margin-top: 16px;
  border-radius: 5px;
  width: 100%;
  .statusGrey {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }
}
.StatusYellow {
  box-shadow: rgba(222, 235, 76, 0.7) 0px 2px 15px;
  border: 1px solid #d3d346;
  margin-top: 16px;
  border-radius: 5px;
  width: 100%;
  .statusYellow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }
}
.statusEmployeName {
  font-family: serif;
  width: 45%;
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  align-items: center;
  overflow-wrap: anywhere;
  h5 {
    margin: 0px 0px 3px 5px;
  }
}

.popup{
  background-color: #ffffff;
  border: 1px solid cyan;
  border-radius: 8px;
  display: flex;
  background-color: #e0f8fb;
  padding: 4px 8px;
}
.popup:hover{
  background-color: #0dcaf0;
  color: #000000;
}
.work-mode{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.checkin-accordian{
  display:'flex' ;
  flex-Direction : row ;
  justify-Content : space-between ;
}

.size{
  height: 20px;
}
.accordion {
  --bs-accordion-btn-padding-y: 7px !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-bg: none !important;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #000000;
}
.checkin {
  display: flex;
  font-family: serif;
  margin-left: -16px;
  flex-direction: column;
}
.checkout {
  display: flex;
  flex-direction: column;
  font-family: serif;
  margin-left: 21px;
}
.dataNotFound {
  font-family: serif;
  text-align: center;
}
.total-in-out {
  font-family: serif;
}

.currPrevNextDate {
  font-size: 25px;
  font-weight: 500;
}
.table td {
  font-family: serif;
  text-align: center;
}
.table th {
  text-align: center;
  font-family: serif;
}

.statusCount {
  min-width: 25px;
  height: 25px;
  color: #ffffff;
  background-color: #000000;
  border-radius: 50%;
  text-align: center;
  padding: 3px;
  font-size: 12px;
  font-weight:bold;
}

@media (min-width: 300px) and (max-width: 768px) {
  .currPrevNextDate {
    font-size: 18px;
    font-weight: 600;
  }
  .checkin {
    display: flex;
    font-size: 12px;
    flex-direction: column;
  }
  .checkout {
    display: flex;
    font-size: 12px;
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .checkin {
    display: flex;
    font-size: 10px;
    flex-direction: column;
  }
  .checkout {
    display: flex;
    font-size: 10px;
    flex-direction: column;
  }
}

.row{
  display:'flex' ;
  flex-Direction : 'row' ;
  justify-Content : 'space-between';
}
.increase-width{
  width: 60%;
}
.text{
  margin: 0 18px 0 0 ;
}
.saveBtnDiv:has(> .disableButton[disabled]){
  font-size: large;
  cursor: not-allowed;
}