.editBody {
    padding: 12px;
    .editContent {
      margin-bottom: 26px;
      display: flex;
      align-items: center;
      padding: 0;
      input {
        margin-left: 50px;
        cursor: pointer;
      }
    }
    .form-switch {
      .form-check-input:checked {
        background-color: red;
        border: 0;
      }
      .form-check-input:focus {
        box-shadow: none;
      }
    }
  }
  .mb_10 {
    margin-bottom: 10px;
  }
  .mb_20 {
    margin-bottom: 20px;
  }
  .errorMsgs {
    color: red;
  }
  