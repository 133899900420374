.videoSection {
  .VideoSec {
    margin-top: 3rem;
    text-align: center;
    margin-bottom: 4rem;
    .VideoBox {
      width: fit-content;
      margin: auto;
      position: relative;
      .leftBox {
        position: absolute;
        width: 160px;
        height: 480px;
        border-right: 2px solid cyan;
        left: 0;
      }
      .rightBox {
        position: absolute;
        width: 163px;
        height: 480px;
        border-left: 2px solid cyan;

        right: 0;
      }
    }
  }
  video#video {
    box-shadow: rgba(0, 229, 255, 0.35) 0px 5px 15px;
  }
  .text-center {
    margin-top: 15px;
  }
}

.captureBtnDiv {
  padding: 5px;
  margin-bottom: 25px;
}
button.captureImageBtn:focus-visible {
  outline: 0;
}
button.captureImageBtn {
  box-shadow: rgba(0, 229, 255, 0.35) 0px 5px 15px;

  position: relative;
  display: inline-block;
  background-color: #ffffff;
  color: #000000;
  font-size: 22px;
  font-weight: 700;
  padding: 1px 20px;
  border: 1px solid white;
  cursor: pointer;
  transition: ease-out 0.5s;
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
}
.captureImageBtn::after,
.captureImageBtn::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  transition: 0.5s;
}

.captureImageBtn::after {
  top: -4px;
  left: -4px;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
}

.captureImageBtn::before {
  bottom: -4px;
  right: -4px;
  border-bottom: 3px solid transparent;
  border-right: 3px solid transparent;
}

.captureImageBtn:hover {
  color: rgb(0, 91, 91);
}

.captureImageBtn:hover::after,
.captureImageBtn:hover::before {
  width: 30px;
  height: 30px;
  border-color: cyan;
}

.position {
  position: absolute;
  top: 5px;
  left: 70px;
}

.renderImages {
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  .imageDiv {
    box-shadow: rgba(0, 229, 255, 0.35) 0px 5px 15px;

    margin: 10px 18px;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
    }
    .crossDiv {
      position: absolute;
      right: 0px;
      top: 0px;
      background-color: #dcdcdcba;
      padding: 0 8px;
      border-radius: 0 0 0 14px;
      cursor: pointer;
      p {
        margin: 0;
        position: relative;
        top: -4px;
        right: -1px;
      }
    }
  }
}
@media (min-width: 300px) and (max-width: 768px){
  .videoSection {
    .VideoSec {
      margin-top: 5rem;
      .VideoBox {
        video#video {
          width: 90%;
        }
        .leftBox {
          height: 100%;
          width: 90px;
          margin-bottom: 5px;
        }
        .rightBox {
          height:100%;
          width: 94px;
          margin-bottom: 5px;
        }
      }
    }
    button.captureImageBtn {
      font-size: 15px;
    }
  }
  .renderImages {
    justify-content: center;
  }
}

