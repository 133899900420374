.AccordionSection {
  font-family: serif;
  .employeBtnBack {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    .employeBtn {
      width: max-content;
      display: flex;
      flex-direction: row;
    }
  }
  div#accordionExample {
    margin-top: 15px;
    .accordion-item {
      margin-top: 20px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      button.accordion-button {
        box-shadow: none !important;
        background-color: #fff !important;
        border: 1px solid cyan;
        color: #000;
        font-size: 25px;
        font-weight: 700;
        .timeBtn {
          display: flex;
          align-items: center;
          .dateSection1 {
            font-weight: 400;
            margin-left: 25px;
            font-size: 20px;
          }
        }
      }
      .accordion-button:not(.collapsed)::after {
        color: #000;
      }
    }
  }
}
.ml_24 {
  margin-left: 24px;
}
.ml_12 {
  margin-left: 12px;
}
.cursorPointer {
  cursor: pointer;
}
.add-punch-btn {
  background-color: #e0f8fb;
  border: 1px solid cyan;
  border-radius: 8px;
  margin-right: 12px;
  font-size: large;
  font-weight: bold;
  padding: 6px;
}
.add-punch-btn:hover {
  color: #000;
  background-color: cyan;
}
.lateWarning {
  margin: 0 4px 0 0;
  padding: 1px 4px 1px 6px;
  background-color: rgb(218, 70, 70);
  color: white;
  width: 40px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}
.payCutWarning {
  margin: 0 4px 0 0;
  padding: 1px 4px 1px 6px;
  // background-color: rgb(5, 11, 131);
  background-color: #dc3545;
  color: white;
  width: 40px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}
.lateIn {
  margin: auto 16px;
  font-size: small;
  background-color: rgb(244, 107, 107);
  border-radius: 12px;
  padding: 4px 6px;
  color: white;
  font-weight: 600;
}