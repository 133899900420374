.DateSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .dateSec {
    padding: 13px;
    input {
      padding: 11px;
      box-shadow: rgb(0 229 255 / 35%) 0px 2px 15px;
      border: 1px solid #68fff1;
      &:focus-visible {
        outline: none;
      }
    }
  }
  .DatePicker {
    display: flex;
  }
  .ButtonDiv {
    display: flex;
    .employeBtn {
      margin: 0 20px;
    }
  }
  .selectDropdown {
    margin-top: 14px;
    margin-left: 12px;
    width: 221px;
  }
  .css-13cymwt-control {
    min-height: 46px;
    box-shadow: rgb(0 229 255 / 35%) 0px 2px 15px;
    border: 1px solid #68fff1;
  }
}
.employeBtn.buttonWidth {
  width: 110px;
}
.modal-content {
  font-family: serif !important;
}
.reportDiv {
  display: flex;
  gap: 20px;
}
.reportBtn {
  box-shadow: rgba(0, 229, 255, 0.35) 0px 5px 15px;
  .ReportBtn {
    position: relative;
    display: inline-block;
    background-color: #ffffff;
    color: #000000;
    font-size: 22px;
    font-weight: 700;
    padding: 2px 18px;
    border: 1px solid white;
    cursor: pointer;
    transition: ease-out 0.5s;
    -webkit-transition: ease-out 0.5s;
    -moz-transition: ease-out 0.5s;
  }
  .ReportBtn::after,
  .ReportBtn::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    transition: 0.5s;
  }

  .ReportBtn::after {
    top: -4px;
    left: -4px;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
  }

  .ReportBtn::before {
    bottom: -4px;
    right: -4px;
    border-bottom: 3px solid transparent;
    border-right: 3px solid transparent;
  }

  .ReportBtn:hover {
    color: rgb(0, 91, 91);
  }

  .ReportBtn:hover::after,
  .ReportBtn:hover::before {
    width: 30px;
    height: 30px;
    border-color: cyan;
  }
}
// css for firefox only
@supports (-moz-appearance:none) {
  .DateSection .selectDropdown {
    width: 186px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .DateSection{
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .DateSection{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .DateSection{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
@media (min-width: 300px) and (max-width:768px) {
  .DateSection{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .reportBtn .ReportBtn{
    font-size: 13.5px;
    font-weight: 700;
    padding: 2px 10px;
  }
  
}
