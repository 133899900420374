.timeBtn {
  display: flex;
  justify-content: space-between;

  padding: 0 10px !important;
}
.accordianKeyName {
  display: flex;
}
.summaryContent {
  box-shadow: none;
}
.title {
  font-size: 17px;
  font-weight: 500;
}

.UpdateButton {
  display: flex;
}
.requestAccordianKey {
  display: flex;
  justify-content: center;
  align-items: center;
}
.requestAccordian {
  width: 100%;
}
h5.empNameId {
  margin-bottom: 0rem !important;
}
.punchTime {
  display: flex;
  justify-content: space-between;
}
.requestError{
color: red;
}
.tabsSec {
  ul#myTab {
    width: 100%;
    display: flex;
    border: 0;
    align-items: center;
    justify-content: center;
    li.nav-item {
      border: 0;
      margin: 0 25px;
      .nav-link,
      .active,
      &:hover,
      :focus-visible,
      :focus {
        border: 0;
        outline: 0;
        color: #000;
      }
      button#profile-tab {
        color: #000;
      }
    }
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .punchTime {
    flex-direction: column;
  }
  .tabsSec ul#myTab {
    gap: 10px;
  }
  h5.empNameId {
    font-size: 17px;
  }
  .AccordionSection
    div#accordionExample
    .accordion-item
    button.accordion-button
    .timeBtn
    .dateSection {
    font-size: 18px;
  }
  .requestAccordianKey {
    display: flex;
    justify-content: start;
  }
}
