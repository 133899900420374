.main-header {
  position: fixed;
  z-index: 10;
}
.HeaderHeading {
  font-family: serif;
  font-weight: 700;
  text-align: center;
}

img.techLogo {
  width: 100%;
}
@media (max-width: 992px) {
  .headerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    button.navbar-toggler {
      border: 0;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
@media (min-width: 300px) and (max-width: 992px) {
  .tech-icon {
    display: none;
  }
  img.techLogo {
    width: 100%;
    height: 50px;
  }
  .HeaderHeading {
    font-size: 15px;
  }
}
