.PublicEmp{
  margin-top: 120px;
}
.AccordionSection {
  font-family: serif;
  .employeNameHeading {
    text-align: center;
    h2 {
      font-weight: 700;
    }
  }
  div#accordionExample {
    margin-top: 15px;
    .accordion-item {
      margin-top: 20px;
      button.accordion-button {
        box-shadow: none !important;
        background-color: #fff !important;
        border: 1px solid cyan;
        color: #000;
        font-size: 25px;
        font-weight: 700;
      }
      .accordion-button:not(.collapsed)::after {
        color: #000;
      }
    }
  }
}

@media (max-width: 420px) {
  .timeBtn {
    display: block !important;
    .dateSection {
      margin-top: 10px !important;
      margin-left: 0 !important;
    }
  }
}
