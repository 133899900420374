.AttendanceSection {
  font-family: serif;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 15px;
  .attendanceDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
}

.goto{
  margin-left: 24px;
}
.btn-Goto{
  border: 1px solid cyan;
  background-color: #e0f8fb;
  border-radius: 8px;
  padding: 0 6px;
  font-size: 14px;
  margin: 0 6px;
}
.btn-Goto:hover{
  background-color: #0dcaf0;
  color: #000000;
}

.employeBtn {
  &.customWidth {
    width: fit-content;
  }
 
  box-shadow: rgba(0, 229, 255, 0.35) 0px 5px 15px;
  button.LogBtn {
    position: relative;
    display: inline-block;
    background-color: #ffffff;
    color: #000000;
    font-size: 22px;
    font-weight: 700;
    padding: 5px 25px;
    border: 1px solid white;
    cursor: pointer;
    transition: ease-out 0.5s;
    -webkit-transition: ease-out 0.5s;
    -moz-transition: ease-out 0.5s;
  }
  .LogBtn::after,
  .LogBtn::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    transition: 0.5s;
  }

  .LogBtn::after {
    top: -4px;
    left: -4px;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
  }

  .LogBtn::before {
    bottom: -4px;
    right: -4px;
    border-bottom: 3px solid transparent;
    border-right: 3px solid transparent;
  }

  .LogBtn:hover {
    color: rgb(0, 91, 91);
  }

  .LogBtn:hover::after,
  .LogBtn:hover::before {
    width: 30px;
    height: 30px;
    border-color: cyan;
  }
}
.employeName {
  display: flex;
  align-items: center;
  h5 {
    margin: 0px 0px 3px 5px;
  }
}

.LeftSideSection {
  display: flex;
  .captureBtn {
    margin-right: 40px;
  }
}

.registerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}
@media (max-width: 768px) {
  .AttendanceSection {
    .DateSection {
      flex-direction: column;
      .DatePicker {
        flex-direction: column;
      }
      .ButtonDiv {
        margin-top: 15px;
        .employeBtn button.LogBtn {
          padding: 10px 15px;
          font-size: 15px;
        }
      }
      .dateSec input {
        width: 100%;
      }
    }
    .attendanceDiv {
      flex-direction: column;
      padding: 25px 0;
      margin: 25px 0;

      .LeftSideSection {
        display: flex;
        flex-direction: column;
        .employeBtn {
          margin-right: 0;
          text-align: center;
          margin: 10px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .AttendanceSection {
    .DateSection {
      flex-direction: column;
      .DatePicker {
        flex-direction: column;
      }
      .ButtonDiv {
        margin-top: 15px;
        .employeBtn button.LogBtn {
          padding: 10px 15px;
          font-size: 15px;
        }
      }
    }
    .attendanceDiv {
      flex-direction: column;
      .employeName {
        margin: 15px 0;
      }
      .employeBtn {
        margin-right: 30px;
        button.LogBtn {
          padding: 10px;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .AttendanceSection {
    .DateSection {
      flex-direction: column;
      .DatePicker {
        flex-direction: column;
      }
      .ButtonDiv {
        margin-top: 15px;
        .employeBtn button.LogBtn {
          padding: 10px 15px;
          font-size: 15px;
        }
      }
    }
    .attendanceDiv {
      flex-direction: column;
      .employeName {
        margin: 15px 0;
      }
      .employeBtn {
        margin-right: 30px;
        button.LogBtn {
          padding: 10px;
        }
      }
    }
  }
  .employeBtn button.LogBtn {
    padding: 5px 15px;
  }
}
