.select{
    width: 100%;
    padding: 6px;
}
.option:hover{
background-color: cyan;
}
.container > label {
    margin: 8px 0;
}
.typeSection{
    label{
        margin-bottom: 5px;
    }
}
.dateSection {
    margin-top: 20px;
    label{
        margin-bottom: 5px;
    }
    .react-datepicker-wrapper {
        width: 100%;
        input{
            width: 100%;
        }
    }
}
.largeFont{
    font-size: large;
}
.saveBtnDiv:has(> .disableButton[disabled]){
    font-size: large;
    cursor: not-allowed;
}