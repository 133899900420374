.loader-div {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;

  .loader-gif {
    width: 15%;
  }
}
