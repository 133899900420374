textarea#username:focus {
  box-shadow: none;
  border-color: #ccc;
}
.footerBtn {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  // .btn-block {
  //   margin-left: 10px;
  // }
}
