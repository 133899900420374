.navbar {
  font-family: serif;
  z-index: 100;
}
.techvalensIcon1 {
  height: 44px;
}
.techvalensIcon2 {
  width: 50px;
}
.attendenceHeading {
  font-weight: 700;
}

.LogoutBtn {
  box-shadow: rgba(0, 229, 255, 0.35) 0px 5px 15px;
  button.LogOutBtn {
    position: relative;
    display: inline-block;
    background-color: #ffffff;
    color: #000000;
    font-size: 22px;
    font-weight: 700;
    padding: 1px 20px;
    border: 1px solid white;
    cursor: pointer;
    transition: ease-out 0.5s;
    -webkit-transition: ease-out 0.5s;
    -moz-transition: ease-out 0.5s;
  }
  .LogOutBtn::after,
  .LogOutBtn::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    transition: 0.5s;
  }

  .LogOutBtn::after {
    top: -4px;
    left: -4px;
    border-top: 3px solid transparent;
    border-left: 3px solid transparent;
  }

  .LogOutBtn::before {
    bottom: -4px;
    right: -4px;
    border-bottom: 3px solid transparent;
    border-right: 3px solid transparent;
  }

  .LogOutBtn:hover {
    color: rgb(0, 91, 91);
  }

  .LogOutBtn:hover::after,
  .LogOutBtn:hover::before {
    width: 30px;
    height: 30px;
    border-color: cyan;
  }
}

.TVLogo {
  width: 150px;
}

@-moz-document url-prefix() {
  .AttendanceContent {
    overflow-y: scroll;
    scrollbar-color: rgb(176, 176, 176) rgb(200, 255, 255);
    scrollbar-width: thin;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .attendenceHeading {
    margin-top: 13px;
    font-size: 15px;
  }
}
@media (min-width: 300px) and (max-width: 425px) {
  .attendenceHeading {
    margin-top: 13px;
    font-size: 13px;
  }
  .techvalensIcon2{
    display: none !important;
  }
}
@media (min-width: 300px) and (max-width: 1200px) {
  .techvalensIcon2 {
    height: 35px;
  }
}
