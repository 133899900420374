.Sidebar {
  position: fixed;
  top: 61px;
  left: 0;
  bottom: 0;
  display: flex;
  width: 200px;
  height: 100%;
  // background-image: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
  background-color: var(--bs-info-bg-subtle);
  // transition:  0.5s all;
  z-index: 6;
}
.sidebar-logo {
  font-size: 16px;
  margin-top: 3px;
}

.sidebar-icon {
  font-size: 22px;
  margin-right: 16px;
  cursor: pointer;
  user-select: none;
  padding: 2px;
}
.sidebar-header {
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 14px;
  padding: 0 8px;
}

.sidebar-items {
  width: 100%;
  gap: 10px;
  margin-top: 3px;
  font-family: serif;
  display: flex;
  font-size: 20px;
  flex-direction: column;
  padding: 0 8px;
}
.sidebar-items .item {
  display: flex;
  align-items: center;
  // height: 40px;
  padding: 8px;
  border-radius: 5px;
  transition: background-color 0.2s;
  cursor: pointer;
}
.sidebar-items .item:hover {
  background-color: #89deee;
}

//for collapse css
.Sidebar.collapsed {
  display: flex;
  flex-direction: column;
  width: 4%;
  transition: 0.5s all;
}
.Sidebar .collapsednone {
  display: none;
}
.Sidebar.collapsed .sidebar-icon {
  margin-right: 0;
  flex: 1;
}
.Sidebar.collapsed .sidebar-text,
.Sidebar.collapsed .sidebar-logo {
  display: none;
}

//col-md css
.sidebar-section-1 {
  width: 14%;
}
.sidebar-section-2 {
  position: relative;
  top: 61px;
  right: 15px;
  width: 85%;
  margin-left: auto;
}
.sidebar-expand-section-2 {
  position: relative;
  top: 61px;
  width: 100%;
}
.AttendanceContent {
  max-height: 600px;
  overflow: auto;
  .InTime {
    width: -webkit-max-content;
    padding-right: 6px;
  }
}
/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: cyan;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
}

//navlink
.navlink {
  text-decoration: none !important;
  color: black !important;
}
.bgRed {
  background-color: #80deea;
  border-radius: 5px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .AttendanceContent {
    max-height: 820px;
  }
}
@media (min-width: 1600px) and (max-width: 1980px) {
  .AttendanceContent {
    max-height: 935px;
  }
}

@media (min-width: 300px) and (max-width: 1200px) {
  .Sidebar.collapsed {
    display: none;
  }
  .sidebar-section-2 {
    right: 0px;
    width: 100%;
  }
  .Sidebar {
    top: 60px;
  }
}

@media (min-width: 1201px) and (max-width: 1496px) {
  .sidebar-section-2 {
    width: 83%;
  }
}

@media (max-width: 768px) {
  .AttendanceContent {
    max-height: calc(100vh - 16vh);
    .statusEmployeName {
      width: -webkit-fill-available;
    }
    .InTime {
      width: max-content;
      padding: 0 10px;
    }
  }
  .sidebar-expand-section-2 {
    top: 63px;
  }
  .sidebar-section-2 {
    top: 63px;
  }
  .DateHeader {
    .current-date {
      padding: 6px 15px;
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .AttendanceContent {
    max-height: calc(100vh - 14vh);
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .AttendanceContent {
    max-height: calc(100vh - 25vh);
  }
  .Sidebar {
    top: 53px;
  }
  .sidebar-expand-section-2 {
    top: 53px;
  }
  .sidebar-section-2 {
    top: 53px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .AttendanceContent {
    max-height: calc(100vh - 19vh);
  }
}
@media (min-width: 1400px) {
  // for safari browswer
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .AttendanceContent {
      max-height: 710px;
      overflow: auto;
    }
  }
}
